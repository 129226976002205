
const loadGoogleMapsApi = require('load-google-maps-api');
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export default class Map {
  static async init(mapElement) {
    try {
      const mapParentElement = mapElement?.parentElement;
      const mapKey = mapElement?.getAttribute('data-aui-map-key');

      if (!mapKey || !mapParentElement) {
        return;
      }

      const googleMaps = await loadGoogleMapsApi({ key: mapKey });

      const { AdvancedMarkerElement } = await googleMaps.importLibrary(
        "marker",
      );

      const mapOptions = JSON.parse(mapElement?.getAttribute('data-aui-map-options')?.replaceAll("'", '"'));

      const map = new googleMaps.Map(mapElement, mapOptions);

      class CustomClusterRenderer {
        render({ count, position }) {
          const { styles } = mapMarkersClustersOptions;

          return new google.maps.Marker({
              position,
              icon: {
                url: styles.icon,
                scaledSize:  new google.maps.Size(styles.size, styles.size),
              },
              label: {
                text: String(count),
                color: styles.color,
                fontSize: styles.fontSize,
              },
              zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
          });
        }
      }

      class Popup extends googleMaps.OverlayView {
        position;
        containerDiv;
        constructor(position, content) {
          super();
          this.position = position;
          content.classList.add('aui-popup-container__anchor-bubble');

          const bubbleAnchor = document.createElement('div');

          bubbleAnchor.classList.add('aui-popup-container__anchor');
          bubbleAnchor.appendChild(content);
          
          this.containerDiv = document.createElement('div');
          this.containerDiv.classList.add('aui-popup-container');
          this.containerDiv.appendChild(bubbleAnchor);

          Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
        }

        /** Called when the popup is added to the map. */
        onAdd() {
          this.getPanes().floatPane.appendChild(this.containerDiv);
        }

        /** Called when the popup is removed from the map. */
        onRemove() {
          if (this.containerDiv.parentElement) {
            this.containerDiv.parentElement.removeChild(this.containerDiv);
          }
        }

        /** Called each frame when the popup needs to draw itself. */
        draw() {
          const divPosition = this.getProjection().fromLatLngToDivPixel(
            this.position,
          );

          // Hide the popup when it is far out of view.
          this.containerDiv.style.left = `${divPosition.x}px`;
          this.containerDiv.style.top = `${divPosition.y}px`;
        }
      }

      const infoWindow = new google.maps.InfoWindow();

      const mapMarkersElements = mapParentElement.querySelectorAll('[data-aui-map-marker]');
      const mapMarkersOptions = JSON.parse(mapElement.getAttribute('data-aui-map-markers-options')?.replaceAll("'", '"'));
      const mapMarkers = [];

      mapMarkersElements.forEach((mapMarkersElement) => {
        const mapMarker = JSON.parse(mapMarkersElement.getAttribute('data-aui-map-marker')?.replaceAll("'", '"'));

        const marker = new googleMaps.Marker({
          ...mapMarkersOptions,
          ...mapMarker,
        });

        if (mapMarkersElements?.length === 1 && mapMarkersElement.innerHTML) {
          const { lat, lng } = mapMarker.position;

          const mapMarkersBubbleElement = document.createElement('div');

          mapMarkersBubbleElement.innerHTML = mapMarkersElement.innerHTML;

          const popup = new Popup(
            new googleMaps.LatLng(lat, lng),
            mapMarkersBubbleElement,
          );

          popup.setMap(map);
        } else if (mapMarkersElement.innerHTML) {
          marker.addListener('click', () => {
            infoWindow.setContent(`${mapMarkersElement.innerHTML}`);
            infoWindow.open(map, marker);
          });
        }

        mapMarkers.push(marker);
      });

      const mapMarkersClustersOptions = JSON.parse(mapElement.getAttribute('data-aui-map-markers-clusters-options')?.replaceAll("'", '"'));
      
      new MarkerClusterer({ map, markers: mapMarkers, renderer: new CustomClusterRenderer()});
    } catch (error) {
      console.error(error);
      console.warn('AUI - Map syntax isnt right');
    }
  }
}