export default class CartPreview {
    static init(container) {
        const cartPreview = container.querySelector('#js-cart-preview');
        if (cartPreview) {
            cartPreview.addEventListener('reload', CartPreview.reload);
        }
    }

    static triggerReload() {
        const cartPreview = document.querySelector('#js-cart-preview');
        if (cartPreview) {
            const event = new Event('reload');
            cartPreview.dispatchEvent(event);
        }
    }
}

naja.addEventListener('init', (event) => {
    CartPreview.init(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    if (event.detail.snippet) {
        CartPreview.init(event.detail.snippet);
    }
});
