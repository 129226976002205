import {onClickOutside} from "../utils/clickOutside";

function initFooterMenu(container) {
    if (!window.aui.grid.md) {
        const footerColumnsMenu = container.querySelectorAll('.footer__menu-columns .column');

        if (footerColumnsMenu?.length) {
            footerColumnsMenu.forEach((footerColumnMenu) => {
                footerColumnMenu.addEventListener('click', (event) => {
                    if (event?.target?.tagName !== 'H5') return;

                    footerColumnMenu.classList.toggle('is-open');
                });

                onClickOutside(footerColumnMenu, () => footerColumnMenu.classList.remove('is-open'));
            });
        }
    }
}

naja.addEventListener('init', () => {
    initFooterMenu(document.body);
});

window.addEventListener('resize', () => {
    initFooterMenu(document.body);
});
