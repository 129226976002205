import Ajax from 'adaptive-ui/src/js/utils/ajax';

export default class RegistrationModalSide {

    constructor(registrationModalSide) {
        const registrationForm = registrationModalSide.querySelector('form');

        registrationForm.addEventListener('submit', (event) => {
            event.preventDefault();

            Ajax.submitForm(loginForm, {
                history: false,
                forceRedirect: true,
                loaderElement: '[data-registration-modal-side]'
            });
        })
    }

    static init(container) {
        container.querySelectorAll('[data-registration-modal-side]').forEach(element => {
            new RegistrationModalSide(element);
        });
    };

}

naja.addEventListener('init', () => {
    RegistrationModalSide.init(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    RegistrationModalSide.init(event.detail.snippet);
});
