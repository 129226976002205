import naja from 'naja';
import Form from '../classes/form';

export default function initForm(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-select-multiple]')?.forEach((selectElement) => {
    Form.initSelectMultiple(selectElement);
  });

  container.querySelectorAll('[data-aui-datepicker]')?.forEach((dateElement) => {
    Form.initDatepicker(dateElement);
  });
}

naja.addEventListener('init', () => {
  initForm(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  initForm(event.detail.snippet);
});