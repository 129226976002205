import Ajax from 'adaptive-ui/src/js/utils/ajax';

export default class LoginModalSide {

    constructor(loginModalSide) {
        const loginForm = loginModalSide.querySelector('form');

        if (!loginForm) {
            return;
        }

        loginForm.addEventListener('submit', (event) => {
            event.preventDefault();

            Ajax.submitForm(loginForm, {
                history: false,
                forceRedirect: true,
                loaderElement: '[data-login-modal-side]'
            });
        })
    }

    static init(container) {
        container.querySelectorAll('[data-login-modal-side]').forEach(element => {
            new LoginModalSide(element);
        });
    };

}

naja.addEventListener('init', () => {
    LoginModalSide.init(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    LoginModalSide.init(event.detail.snippet);
});
