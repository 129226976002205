import naja from 'naja';
import Scroll from '../classes/scroll';

export default function initScroll(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-auto-scroll]')?.forEach((scrollElement) => {
    Scroll.initAutoScroll(scrollElement);
  });

  container.querySelectorAll('[data-aui-smooth-scroll]')?.forEach((scrollElement) => {
    Scroll.initSmoothScroll(scrollElement);
  });

  container.querySelectorAll('[data-aui-go-to-top]')?.forEach((scrollElement) => {
    Scroll.initScrollToTop(scrollElement);
  });
}

naja.addEventListener('init', () => {
  initScroll(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  initScroll(event.detail.snippet);
});