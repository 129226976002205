import JsCookie from 'js-cookie';

export default class Cookies {
  static set(name, value, options = {
    path: '/',
  }) {
    JsCookie.set(name, value, options);
  }

  static get(name) {
    return JsCookie.get(name);
  }

  static remove(name, options = {
    path: '/',
  }) {
    JsCookie.remove(name, options);
  }
};
