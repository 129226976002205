import naja from 'naja';
import Spinbox from '../classes/spinbox';

export default function initSpinbox(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-spinbox]')?.forEach((mapElement) => {
    Spinbox.init(mapElement);
  });
}

naja.addEventListener('init', () => {
  initSpinbox(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  initSpinbox(event.detail.snippet);
});
