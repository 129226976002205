import naja from 'naja';
import Map from '../classes/map';

export default function initMap(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-map]')?.forEach((mapElement) => {
    Map.init(mapElement);
  });
}

naja.addEventListener('init', () => {
  initMap(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  initMap(event.detail.snippet);
});