import naja from 'naja';
import Tooltip from '../classes/tooltip';

export default function initTooltip(paramContainer) {
  const container = paramContainer || document.body;

  const tooltips = container.querySelectorAll('[data-aui-tooltip]');

  if (tooltips?.length) {
    Tooltip.init(tooltips);
  } 
}

naja.addEventListener('init', () => {
  initTooltip(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  initTooltip(event.detail.snippet);
});