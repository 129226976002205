import naja from 'naja';
import ShowSlide from '../classes/show-slide';

export default function initShowSlide(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-show-slide]')?.forEach((showSlideElement) => {
    ShowSlide.init(showSlideElement);
  });
}

naja.addEventListener('init', () => {
  initShowSlide(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  initShowSlide(event.detail.snippet);
});