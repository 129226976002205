import { mergeWith } from 'lodash';
import Toastify from 'toastify-js'

const defaultOptions = {
  text: '',
  className: 'aui-toast aui-toast--info',
  position: 'center',
  duration: 3000,
  close: true,
};

export default class Toast {
  constructor(options) {
    this.options = mergeWith({}, defaultOptions, options);
    this.options.className = `aui-toast aui-toast--${this.options.type}`;

    Toastify(this.options).showToast();
  }
}