import naja from 'naja';
import Viewport from '../classes/viewport';

export default function initViewport() {
  window.aui = {
    ...window.aui,
    breakpoints: Viewport.getBreakpoints(),
    grid: Viewport.getGrid(),
  }
}

naja.addEventListener('init', initViewport);

naja.snippetHandler.addEventListener('afterUpdate', initViewport);

window.addEventListener('resize', () => {
  window.aui = {
    ...window.aui,
    grid: Viewport.getGrid(),
  }
});