export default class Repeater {
  constructor() {
    this.START_DELAY = 500;
    this.REPEAT_INTERVAL = 100;
    this.timerDelay = null;
    this.timerRepeat = null;
  }

  startAutorepeat(element, eventString) {
    element.dispatchEvent(new Event(eventString));
    this.stopAutorepeat();
    this.timerDelay = setTimeout(() => {
      element.dispatchEvent(new Event(eventString));
      this.timerRepeat = setInterval(() => {
        element.dispatchEvent(new Event(eventString));
      }, this.REPEAT_INTERVAL);
    }, this.START_DELAY);
  }

  stopAutorepeat() {
    clearTimeout(this.timerDelay);
    clearInterval(this.timerRepeat);
  }
}
