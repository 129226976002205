export default class ModalSide {
  /**
   * Otevře modal side podle jeho ID
   * @param {string} modalSideId
   */
  static showModalSide(modalSideId) {
    const modalSide = document.querySelector(`[data-modal-side-id="${modalSideId}"]`);
    const modalSideBackground = document.querySelector('[data-modal-side-background]');
    if (modalSide) {
      this.closeAllModalSides();
      modalSide.classList.add('modal-side--is-open');
      if (modalSideBackground) {
        modalSideBackground.classList.add('modal-side-background--is-open');
      }
    } else {
      console.warn(`Modal side "${modalSideId}" not found`);
    }
  }

  /**
   * Zavře všechny modal sides
   */
  static closeAllModalSides() {
    const modalSides = document.querySelectorAll('[data-modal-side-id]');
    const modalSideBackground = document.querySelector('[data-modal-side-background]');
    modalSides.forEach((modalSide) => {
      modalSide.classList.remove('modal-side--is-open');
    });
    if (modalSideBackground) {
      modalSideBackground.classList.remove('modal-side-background--is-open');
    }
  }

  /**
   * Inicializuje tlačítka, která otevírají modal sides
   */
  static initTriggers() {
    const triggers = document.querySelectorAll('[data-modal-side]');
    triggers.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        const modalSideId = trigger.getAttribute('data-modal-side');
        this.showModalSide(modalSideId);
      });
    });
  }

  /**
   * Inicializuje tlačítka a pozadí pro zavírání modal sides
   */
  static initCloseButtons() {
    const closeButtons = document.querySelectorAll('[data-modal-side-close]');
    const modalSideBackground = document.querySelector('[data-modal-side-background]');
    closeButtons.forEach((closeButton) => {
      closeButton.addEventListener('click', () => {
        const modalSide = closeButton.closest('[data-modal-side-id]');
        if (modalSide) {
          modalSide.classList.remove('modal-side--is-open');
        }
        if (modalSideBackground) {
          modalSideBackground.classList.remove('modal-side-background--is-open');
        }
      });
    });

    if (modalSideBackground) {
      modalSideBackground.addEventListener('click', () => {
        this.closeAllModalSides();
      });
    }
  }

  /**
   * Inicializuje modal-side (triggery i zavírací tlačítka)
   */
  static init() {
    this.initTriggers();
    this.initCloseButtons();
  }
}

// Příklad použití:
naja.addEventListener('init', () => {
  ModalSide.init();
});

naja.snippetHandler.addEventListener('afterUpdate', () => {
  ModalSide.init();
});
