
import Macy from 'macy'; // https://github.com/bigbite/macy.js

export default class Gallery {
  static initMasonry(masonryElement) {
    const defaultOptions = {
      trueOrder: false,
      waitForImages: true,
      margin: 10,
      columns: 3,
      breakAt: {
        1200: 3,
        940: 2,
        520: 1,
      },
    };

    let options = masonryElement.getAttribute('data-aui-grid-masonry');

    try {
      options = JSON.parse(masonryElement.getAttribute('data-aui-grid-masonry'));
    } catch(error) {
      options = {};
    }

    masonryElement.insertAdjacentHTML('afterbegin', `
      <div class="aui-image aui-grid-masonry__ghost"></div>
      <div class="aui-image aui-grid-masonry__ghost"></div>
      <div class="aui-image aui-grid-masonry__ghost"></div>
      <div class="aui-image aui-grid-masonry__ghost"></div>
      <div class="aui-image aui-grid-masonry__ghost"></div>
      <div class="aui-image aui-grid-masonry__ghost"></div>  
    `);
  
    const masonry = Macy({
      container: masonryElement,
      ...defaultOptions,
      ...options,
    });

    masonry.recalculate();

    masonry.runOnImageLoad(() => {
      masonry.recalculate();

      setTimeout(() => {
        masonryElement.classList.add('aui-grid-masonry--loaded');
        masonry.recalculate(true);
      }, 300);
    });
  }
}