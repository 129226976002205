
import Cookies from '../utils/cookies';

export default class Announcement {
  static init(announcementElement) {
    const announcementElementCloseElements = announcementElement.querySelectorAll('[data-aui-announcement-close]');

    announcementElementCloseElements?.forEach((announcementElementCloseElement) => {
      announcementElementCloseElement.addEventListener('click', (event) => {
        event.preventDefault();

        const hash = announcementElement.getAttribute('data-aui-announcement-hash');

        if (hash) {
          Cookies.set( `announcement-${hash}`, true);

          announcementElement.remove();
        }
      })
    });
  }
}