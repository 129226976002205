import Repeater from './../utils/repeater';

export default class Spinbox {
  static init = (spinbox) => {
    const input = spinbox.querySelector('input[type="number"]');
    const plus = spinbox.querySelector('[data-aui-spinbox-plus]');
    const minus = spinbox.querySelector('[data-aui-spinbox-minus]');
    const repeater = new Repeater();

    input.addEventListener('spinbox.plus', Spinbox.plus);
    input.addEventListener('spinbox.minus', Spinbox.minus);

    plus.addEventListener('mousedown', () => {
      repeater.startAutorepeat(input, 'spinbox.plus');
    });
    plus.addEventListener('mouseup', () => {
      repeater.stopAutorepeat();
    });
    plus.addEventListener('mouseout', () => {
      repeater.stopAutorepeat();
    });

    minus.addEventListener('mousedown', () => {
      repeater.startAutorepeat(input, 'spinbox.minus');
    });
    minus.addEventListener('mouseup', () => {
      repeater.stopAutorepeat();
    });
    minus.addEventListener('mouseout', () => {
      repeater.stopAutorepeat();
    });
  };

  static plus = function () {
    Spinbox.changeValue(this, '+');
  };

  static minus = function () {
    Spinbox.changeValue(this, '-');
  };

  static changeValue = (input, action) => {
    let value = input.value || input.min;
    const {min, max} = input;

    if (value.match(/^\d*\.?\d*$/)) {
      value = Number(value);
      const step = input.step ? Number(input.step) : 1;

      if (action === '+') {
        value = value + step;
      } else {
        value = value - step;
      }

      if (min !== undefined && min > value) {
        value = min;
      }

      if (max !== undefined && max < value) {
        value = max;
      }

      input.value = value.toFixed(step?.toString()?.split('.')?.[1]?.length || 0);
      input.dispatchEvent(new Event('change'));
    }
  };
}
