import Helpers from "../helpers";
import flatpickr from "flatpickr";
import { Czech } from "flatpickr/dist/l10n/cs.js"
export default class Form {
  static initSelectMultiple(selectElement) {
    const select = selectElement;
    const newSelect = document.createElement('div');
    newSelect.classList.add('aui-select-multiple');

    const active = document.createElement('div');
    active.classList.add('aui-select-multiple__input');

    const optionList = document.createElement('div');
    optionList.classList.add('aui-select-multiple__list');

    const searchInput = document.createElement('input');
    searchInput.type = 'text';
    searchInput.placeholder = 'Vyhledat možnosti';
    searchInput.classList.add('aui-select-multiple__list-search');
    optionList.appendChild(searchInput);

    const placeholder = select.getAttribute('placeholder');
    const span = document.createElement('span');
    span.classList.add('aui-select-multiple__input-placeholder');
    span.innerText = placeholder;
    active.appendChild(span);

    const processOption = (option, optionGroupId) => {
      const { value, selected, innerText: text } = option;

      const item = document.createElement('div');
      item.classList.add('aui-select-multiple__list-option');
      item.dataset.value = value;
      item.innerHTML = text;
      item.dataset.group = optionGroupId;

      if (selected) {
        const tag = document.createElement('a');
        tag.classList.add('aui-select-multiple__input-option');
        tag.dataset.value = value;
        tag.innerHTML = `<em>${text}</em><i></i>`;
        active.appendChild(tag);
        span.classList.add('hide');
        item.classList.add('is-active');
      }

      optionList.appendChild(item);
    };

    [...select.children].forEach(child => {
      if (child.tagName.toLowerCase() === 'optgroup') {
        const groupLabel = document.createElement('div');
        groupLabel.classList.add('aui-select-multiple__list-group-label');
        groupLabel.innerText = child.label;
        optionList.appendChild(groupLabel);

        const optionGroupId = Helpers.generateRandomId();

        [...child.children].forEach(option => processOption(option, optionGroupId));
      } else if (child.tagName.toLowerCase() === 'option') {
        processOption(child);
      }
    });

    newSelect.append(active, optionList);
    select.parentElement.append(newSelect);
    span.appendChild(select);

    document.querySelector('.aui-select-multiple__list').addEventListener('click', (e) => {
      const li = e.target.closest('.aui-select-multiple__list-option');
      if (!li) return;

      const select = li.closest('.aui-select-multiple');
      if (!select.classList.contains('clicked')) {
        select.classList.add('clicked');

        const activeA = select.querySelector(`.aui-select-multiple__input [data-value="${li.dataset.value}"]`);

        if (!activeA) {
          const a = document.createElement('a');
          a.dataset.value = li.dataset.value;
          a.innerHTML = `<em>${li.innerText}</em><i></i>`;
          select.querySelector('div').appendChild(a);
        } else {
          activeA.remove();
        }

        const selectEl = select.querySelector('select');
        const opt = selectEl.querySelector(`option[value="${li.dataset.value}"]`);
        opt.setAttribute('selected', 'selected');

        select.querySelector('.aui-select-multiple__input-placeholder').classList.add('hide');

        li.classList.toggle('is-active');
        select.classList.remove('clicked');

        selectEl.dispatchEvent(new Event('change'));
      }
    });

    document.querySelector('.aui-select-multiple__input').addEventListener('click', (e) => {
      const a = e.target.closest('a');
      if (!a) return;

      const select = a.closest('.aui-select-multiple');
      const selectInput = a.closest('.aui-select-multiple__input');
      selectInput.classList.add('aui-select-multiple__input--is-open');

      const selectEl = select.querySelector('select');
      const opt = selectEl.querySelector(`option[value="${a.dataset.value}"]`);
      opt.removeAttribute('selected');

      select.querySelector(`.aui-select-multiple__list-option[data-value="${a.dataset.value}"]`)?.classList.remove('is-active');

      if (!selectEl.selectedOptions.length) {
        select.querySelector('.aui-select-multiple__input-placeholder').classList.remove('hide');
      }

      a.remove();

      selectEl.dispatchEvent(new Event('change'));
    });

    searchInput.addEventListener('input', (e) => {
      const searchValue = e.target.value.toLowerCase();
      const options = document.querySelectorAll('.aui-select-multiple__list-option');
      const groupLabels = document.querySelectorAll('.aui-select-multiple__list-group-label');

      options.forEach(option => {
        const text = option.innerText.toLowerCase();
        option.style.display = text.includes(searchValue) ? '' : 'none';
      });

      groupLabels.forEach(label => {
        const groupId = label.nextElementSibling.dataset.group;
        const groupOptions = document.querySelectorAll(`.aui-select-multiple__list-option[data-group="${groupId}"]`);
        const isGroupEmpty = Array.from(groupOptions).every(option => option.style.display === 'none');
        label.style.display = isGroupEmpty ? 'none' : '';
      });
    });

    document.querySelectorAll('.aui-select-multiple__input').forEach(el => {
      // Click outside
      document.addEventListener('click', (event) => {
        const select = event.target.closest('.aui-select-multiple');
        if (!select && !event.target?.classList.contains('aui-select-multiple__list-option')) {
          el.classList.remove('aui-select-multiple__input--is-open');
        }
      });

      el.addEventListener('click', () => {
        el.classList.toggle('aui-select-multiple__input--is-open');
        if (el.classList.contains('aui-select-multiple__input--is-open')) {
          const searchInput = el.nextElementSibling.querySelector('.aui-select-multiple__list-search');
          setTimeout(() => searchInput.focus(), 0);
        }
      });
    });
  }

  static initDatepicker(dateElement) {
    let options = {};

    try {
      options = JSON.parse(dateElement.getAttribute('data-aui-datepicker') || '{}');
    } catch (error) {
      console.error(error);
    }

    flatpickr(dateElement, {
      ...options,
      locale: Czech,
    });
  }
}