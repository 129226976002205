import naja from 'naja';
import Video from '../classes/video';

export default function initVideo(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-video]')?.forEach((videoElement) => {
    Video.init(videoElement);
  });
}

naja.addEventListener('init', () => {
  initVideo(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  initVideo(event.detail.snippet);
});
