import Splide from '@splidejs/splide'; // https://splidejs.com/
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { mergeWith } from 'lodash';

const defaultOptions = {
  type: 'loop',
  perPage: 3,
  gap: '1.5rem',
};

export default class Slider {
  static init(sliderElement) {
    const elementOptions = JSON.parse(sliderElement.getAttribute('data-aui-slider-options') || null) || {};
    const options = mergeWith({}, defaultOptions, elementOptions);
    const sliderName = sliderElement.getAttribute('data-aui-slider') || null;
    let mountExtensions = {};

    if (options.autoScroll) {
      mountExtensions = { AutoScroll };
    }

    const slider = new Splide(sliderElement, options).mount(mountExtensions);

    if (sliderName) {
      window.aui.activeSliders = {
        ...(window.aui.activeSliders || {}),
        [sliderName]: slider,
      };
    }
  }
}