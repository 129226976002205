import Ajax from './../utils/ajax';
import * as VanillaCookieConsent from 'vanilla-cookieconsent';

const logConsent = () => {
  if (cookiesPolicyLogUrl === undefined || !cookiesPolicyLogUrl) {
    return;
  }

  const preferences = VanillaCookieConsent.getUserPreferences();

  Ajax.post(cookiesPolicyLogUrl, {
    acceptType: preferences.acceptType,
    acceptedCookieCategories: preferences.acceptedCategories
  }, {
    history: false,
    loaderElement: false,
    unique: false
  });
}

const updateGtag = (cookieCategory, granted) => {
  gtag('consent', 'update', {
    [cookieCategory]: granted === true ?
        'granted' :
        'denied'
  });
}

export default class CookiesPolicy {
  static init() {
    if (typeof cookiesPolicySettings === 'undefined' || !cookiesPolicySettings) {
      return;
    }

    VanillaCookieConsent.run({
      ...cookiesPolicySettings,
      onFirstConsent: () => {
        VanillaCookieConsent.getCookie()?.categories?.forEach((cookieCategory) => {
          updateGtag(cookieCategory, true);
        });

        logConsent();
      },
      onChange: ({cookie, changedCategories}) => {
        changedCategories?.forEach((cookiesCategory) => {
          updateGtag(
              cookiesCategory,
              cookie.categories.includes(cookiesCategory)
          );
        });

        logConsent();
      }
    });

    document.querySelectorAll('[data-aui-cookies-policy-show-preferences]').forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault();
        VanillaCookieConsent.showPreferences();
      })
    });
  }
}
