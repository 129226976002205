const dpdOverlayHtmlId = 'js-dpd-place-selector-overlay';
const dpdIframeWrapHtmlId = 'js-dpd-place-selector-iframe-wrap';
const dpdIframeHtmlId = 'js-dpd-place-selector-iframe';
const dpdInputSelector = '[data-dpd-place-selector-input]';
const dpdResultSelector = '[data-dpd-place-selector-result]';

class DpdPlaceSelector {

    constructor(element) {
        const _this = this;

        const radio = element.querySelector('input[type="radio"]');
        document.querySelectorAll('input[type="radio"][name="transportAndPayment[transport]"]').forEach(input => {
            input.addEventListener('change', function () {
                if (this.checked === true && this.dataset.id != radio.dataset.id) {
                    const result = document.querySelector(dpdResultSelector);
                    result.innerText = result.dataset.emptyText;
                }
            });
        });

        element.addEventListener('click', function () {
            _this.createIframe(element);
        });
    }

    createIframe(element) {
        let parameters = '';

        if (element.dataset.country) {
            parameters += 'enabledCountries=' + element.dataset.country;
        }

        const iframeSrc = 'https://api.dpd.cz/widget/latest/index.html?' + parameters;

        const iframeHtml = `
            <div id="${dpdOverlayHtmlId}">
                <div id="${dpdIframeWrapHtmlId}">
                    <iframe id="${dpdIframeHtmlId}" src="${iframeSrc}" allow="geolocation"></iframe>
                </div>
            </div>`;

        const bodyNode = document.body;
        const iframeNode = document.createElement('div');
        iframeNode.innerHTML = iframeHtml;

        bodyNode.insertBefore(iframeNode, bodyNode.firstChild);

        this.addStylesToOverlay();
        this.addStylesToIframeWrap();
        this.addStylesToIframe();

        window.addEventListener("message", (event) => {
            if (event.data.dpdWidget && event.data.dpdWidget.message === "widgetClose") {
                this.removeIframe();
                return;
            }

            if (event.data.dpdWidget) {
                document.querySelector(dpdInputSelector).value = event.data.dpdWidget.id;
                document.querySelector(dpdResultSelector).innerText = event.data.dpdWidget.pickupPointResult.split(", ").slice(1).join(", ");

                this.removeIframe();
            }
        }, false);
    }

    removeIframe() {
        const widget = document.getElementById(dpdOverlayHtmlId);
        if (widget) {
            widget.remove();
        }
    }

    addStylesToOverlay() {
        const overlay = document.getElementById(dpdOverlayHtmlId);

        const styles = {
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.6)',
            position: 'fixed',
            zIndex: '9999999'
        };

        this.applyStyles(overlay, styles);
    }

    addStylesToIframeWrap() {
        const iframeWrap = document.getElementById(dpdIframeWrapHtmlId);

        const styles = {
            border: 'none',
            width: '100%',
            height: '100%',
            maxWidth: '1000px',
            position: 'fixed',
            zIndex: '9999999',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'white'
        };

        this.applyStyles(iframeWrap, styles);
    }

    addStylesToIframe() {
        const iframe = document.getElementById(dpdIframeHtmlId);

        const styles = {
            border: 'none',
            width: '100%',
            height: '100%'
        };

        this.applyStyles(iframe, styles);
    }

    applyStyles(node, styles) {
        Object.keys(styles).forEach(key => {
            node.style[key] = styles[key];
        });
    }

    static init(container) {
        container.querySelectorAll('[data-dpd-place-selector]').forEach(element => {
            new DpdPlaceSelector(element);
        });
    }
}

naja.addEventListener('init', (event) => {
    DpdPlaceSelector.init(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    DpdPlaceSelector.init(event.detail.snippet);
});
