import naja from 'naja';
import Modal from '../classes/modal';
import Ajax from '../utils/ajax';

export function initModal() {
  window.aui = {
    ...window.aui,
    modal: Modal,
    activeModal: null
  }
}

export function initModalGet(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-modal-get]')?.forEach((element) => {
    if (element.tagName?.toUpperCase() === 'A' && element.hasAttribute('href')) {
      element.addEventListener('click', async (event) => {
        event.preventDefault();
        const response = await Ajax.get(
            element.getAttribute('href'),
            {},
            {
              history: false,
              loaderElement: window?.aui?.activeModal?.options?.el?.querySelector('[data-aui-modal-container]')
            }
        );

        if (response) {
          const modal = window?.aui?.activeModal ?? new window.aui.modal();

          naja.snippetHandler.updateSnippet(modal.options.el.querySelector('[data-aui-modal-title]'), response.title);
          naja.snippetHandler.updateSnippet(modal.options.el.querySelector('[data-aui-modal-body]'), response.body);
        }
      });
    }
  });
}

naja.addEventListener('init', () => {
  initModal();
  initModalGet(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  initModalGet(event.detail.snippet);
});