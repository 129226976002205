import Ajax from 'adaptive-ui/src/js/utils/ajax';
import CartPreview from "./CartPreview";

export default class CartPreviewBox {

    constructor(cartPreviewBox) {
        cartPreviewBox.querySelectorAll('[data-aui-cart-preview-items-remove]').forEach(element => {
            element.addEventListener('click', event => {
                event.preventDefault();

                Ajax.post(element.getAttribute('href'), {}, {
                    history: false,
                    loaderElement: cartPreviewBox,
                }).then(() => {
                    CartPreview.triggerReload();
                });
            });
        });
    }

    static init (container) {
        container.querySelectorAll('[data-aui-cart-preview-box]').forEach(cartPreviewBox => {
            new CartPreviewBox(cartPreviewBox);
        });
    }
}

naja.addEventListener('init', () => {
    CartPreviewBox.init(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    CartPreviewBox.init(event.detail.snippet);
});

