import naja from 'naja';
import Header from '../classes/header';

export default function initHeader(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-header]')?.forEach((headerElement) => {
    Header.init(headerElement);
  });
}

naja.addEventListener('init', () => {
  initHeader(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  initHeader(event.detail.snippet);
});