import naja from 'naja';
import Toast from '../classes/toast';

export default function initToast() {
  window.aui = {
    ...window.aui,
    toast: Toast,
  }
}

naja.addEventListener('init', initToast);

naja.snippetHandler.addEventListener('afterUpdate', initToast);