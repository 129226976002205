import naja from 'naja';
import Grid from '../classes/grid';

export default function initGrid(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-grid-masonry]')?.forEach((gridElement) => {
    Grid.initMasonry(gridElement);
  });
}

naja.addEventListener('init', () => {
  initGrid(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  initGrid(event.detail.snippet);
});