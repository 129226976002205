export default class Viewport {
  static getBreakpoints() {
    const breakpointsCss = getComputedStyle(document.documentElement).getPropertyValue('--aui-breakpoints-string');

    if (breakpointsCss) {
      const breakpoints = breakpointsCss.split(' ')?.map((item) => {
        const [ key, value ] = item.split('-');
  
        return {
          [key]: Number(value?.replace('px', '')),
        }
      })?.reduce((result, current) => {
        return {
          ...result,
          ...current,
        }
      });

      return breakpoints;
    }
  };

  static getGrid() {
    const breakpoints = this.getBreakpoints();

    if (breakpoints) {
      const gridKeys = Object.keys(breakpoints);
      const grid = {};
      const windowWidth = window.innerWidth;

      gridKeys.forEach((key) => {
        grid[key] = windowWidth >= breakpoints[key];
      });

      return grid;
    }
  };
};