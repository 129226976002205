export default class Tabs {
  constructor(container) {
      this.tabButtons = Array.from(container.querySelectorAll('[data-aui-tabs-button]'));
      this.tabContents = Array.from(container.querySelectorAll('[data-aui-tabs-content]'));
  }

  init() {
      this.tabButtons.forEach(button => {
          button.addEventListener('click', (event) => Tabs.onClickTabButton(event, this));
      });

      this.fixTabsState();
  }

  fixTabsState() {
      const activeButtons = this.tabButtons.filter(button => button.classList.contains('is-active'));
      if (activeButtons.length > 0) {
          this.activateOneTabAndDeactivateOther(activeButtons[activeButtons.length - 1]?.dataset.auiTabsButton);
      } else {
          this.activateOneTabAndDeactivateOther(this.tabButtons[0]?.dataset?.auiTabsButton);
      }
  }

  static onClickTabButton(event, tabs) {
      const tabId = event.currentTarget.dataset.auiTabsButton;
      tabs.activateOneTabAndDeactivateOther(tabId);

      event.preventDefault();
  }

  // activates exactly one tab (in "single" mode)
  activateOneTabAndDeactivateOther(tabId) {
      this.tabButtons.forEach(button => {
          const currentTabId = button.dataset.auiTabsButton;
          const isCurrentTab = currentTabId === tabId;

          this.toggleTab(currentTabId, isCurrentTab);
      });
  }

  // use true to show the tab or false to hide it without checking single/multiple mode
  toggleTab(tabId, display) {
      const tabButton = this.tabButtons.find(button => button.dataset.auiTabsButton === tabId);
      const tabContent = this.tabContents.find(content =>  content.dataset.auiTabsContent === tabId);

      if (tabButton) {
          if (display === true) {
              tabButton.classList.add('is-active');
          } else {
              tabButton.classList.remove('is-active');
          }
      }

      if (tabContent) {
          tabContent.classList.toggle('is-active', display);
      }
  }
}