import {onClickOutside} from "../utils/clickOutside";

function initHeaderMenu(container) {
    if (window.innerWidth < 1350) {
        const headerColumnsMenu = container.querySelectorAll('.aui-header-burger .aui-navigation__dropdown .aui-navigation__item');

        if (headerColumnsMenu?.length) {
            headerColumnsMenu.forEach((headerColumnMenu) => {
                headerColumnMenu.addEventListener('click', (event) => {
                    headerColumnMenu.parentElement.classList.toggle('is-open');
                });

                onClickOutside(headerColumnMenu.parentElement, () => headerColumnMenu.parentElement.classList.remove('is-open'))
            });
        }
    }
}

naja.addEventListener('init', () => {
    initHeaderMenu(document.body);
});

window.addEventListener('resize', () => {
    initHeaderMenu(document.body);
});
