import Helpers from "../helpers";
import { mergeWith } from "lodash";
import Translator from "../utils/translator";

const defaultOptions = {
  type: 'base',
  position: 'center',
  title: '',
  body: '',
  buttons: {
    close: {
      text: Translator.translate('Zavřít'),
      icon: '',
      isVisible: true,
    },
    submit: {
      text: Translator.translate('Ano'),
      icon: '',
      url: '#',
      isVisible: false,
    },
    cancel: {
      text: Translator.translate('Ne'),
      icon: '',
      isVisible: false,
    },
  },
  class: '',
  closeIcon: `
    <svg style="enable-background:new 0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M443.6 387.1 312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z" fill="currentColor"/>
    </svg>
  `,
  bounded: true,
  lockScroll: true,
  selfClose: true,
  onOpen: () => {},
  onClose: () => {},
  onSubmit: () => {},
  onCancel: () => {},
};

export default class Modal {
  constructor(options) {
    this.options = mergeWith({}, defaultOptions, options);

    const modalId = Helpers.generateRandomId();

    let modal = `
      <div data-aui-modal="${modalId}" class="aui-modal aui-modal--${this.options.type} aui-modal--${this.options.position} ${this.options.lockScroll ? 'aui-modal--lock-scroll' : ''} ${this.options.bounded ? 'aui-modal--bounded' : ''} ${this.options.class}">
        <div class="aui-modal__container" data-aui-modal-container>
          <div class="aui-modal__container-header">
            <div data-aui-modal-title>
              ${ this.options.title }
            </div>
            {{closeButton}}
          </div>
          <div class="aui-modal__container-content" data-aui-modal-body>
            ${ this.options.body }
          </div>
          {{footer}}
        </div>
      </div>
    `;

    const modalCloseButtonHtml = this.options.buttons?.close?.isVisible ? `
      <button data-aui-modal-close type="button" class="aui-button aui-button--regular-text">
        <div class="aui-icon">
          ${ this.options.closeIcon }
        </div>
      </button>
    ` : '';

    modal = modal.replaceAll('{{closeButton}}', modalCloseButtonHtml);

    const modalFooterHtml = (this.options.buttons?.submit?.isVisible || this.options.buttons?.cancel?.isVisible) ? `
      <div class="aui-modal__container-footer">
        ${
          this.options.buttons?.cancel?.isVisible ? `
            <button data-aui-modal-cancel type="button" class="aui-button aui-button--regular-outline">
              <span>
                ${this.options.buttons?.cancel?.text}
              </span>
            </button>
          ` : ''
        }
        ${
          this.options.buttons?.submit?.isVisible ? `
            <a href="${this.options.buttons.submit.url}" data-aui-modal-submit class="aui-button aui-button--primary">
              <span>
                ${this.options.buttons?.submit?.text}
              </span>
            </a>
          ` : ''
        }
      </div>
    ` : ''; 

    modal = modal.replaceAll('{{footer}}', modalFooterHtml);

    document.body.insertAdjacentHTML('beforeend', modal);

    const modalElement = document.querySelector(`[data-aui-modal="${modalId}"]`);

    this.options.el = modalElement;

    const modalCloseButtons = modalElement.querySelectorAll('[data-aui-modal-close]');

    if (modalCloseButtons) {
      modalCloseButtons.forEach((modalCloseButton) => {
        modalCloseButton.addEventListener('click', this.close.bind(this));
      });
    }

    const modalCancelButtons = modalElement.querySelectorAll('[data-aui-modal-cancel]');

    if (modalCancelButtons) {
      modalCancelButtons.forEach((modalCancelButton) => {
        modalCancelButton.addEventListener('click', this.cancel.bind(this));
      });
    }

    const modalSubmitButtons = modalElement.querySelectorAll('[data-aui-modal-submit]');

    if (modalSubmitButtons) {
      modalSubmitButtons.forEach((modalSubmitButton) => {
        modalSubmitButton.addEventListener('click', this.submit.bind(this));
      });
    }

    if (this.options.selfClose) {
      modalElement.addEventListener('click', (event) => {
        if (event.target === modalElement) {
          this.close();
        }
      });
    }

    document.body.addEventListener('keyup', (event) => {
      if (event.keyCode === 27) { // esc
        this.close();
      }
    });

    this.open();
  }

  open() {
    window.aui.activeModal = this;

    this.options.el.classList.add('aui-modal--is-open');

    this.options.onOpen();
  };

  close() {
    this.options.onClose();
    
    window.aui.activeModal = null;
    
    this.options.el.remove();
  };

  submit(event) {
    const submitUrl = this.options.buttons?.submit?.url;

    if (!submitUrl || submitUrl === '#') {
      event.preventDefault();
    }
    
    this.options.onSubmit();

    this.close();
  };

  cancel() {
    this.options.onCancel();

    this.close();
  };
}