import naja from 'naja';
import Counter from '../classes/counter';

export default function initCounter(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-counter]')?.forEach((counterElement) => {
    Counter.init(counterElement);
  });
}

naja.addEventListener('init', () => {
  initCounter(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  initCounter(event.detail.snippet);
});