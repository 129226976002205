import SentryErrorHandler from 'adaptive-ui/src/js/utils/sentryErrorHandler';

if (__DEV__ === false) {
    SentryErrorHandler.init('https://d38703843621be2fd51efa42f612ae85@o4507418212040704.ingest.de.sentry.io/4507650213150800');
}

import 'adaptive-ui/src/js/aui';

import './components/modal-side';
import './components/slider';
import './components/animations';
import './components/newsletter';
import './components/navigation';
import './components/headerMenu';
import './components/footerMenu';

// cart
import './cart/CartPreview';
import './cart/CartPreviewBox';
import './cart/CartRecalculator';
import './cart/PromoCode';

// product
import './product/AddToCart';

// order
import './order/OrderTransportAndPayment';
import './order/OrderSummaryBox';
import './order/DpdPlaceSelector';

// user
import './user/LoginModalSide';
import './user/RegistrationModalSide';

import netteForms from "nette-forms";

// Resize
window.addEventListener('resize', () => {
    const headerElement = document.querySelector('.aui-header');
    const burgerButton = headerElement.querySelector('[data-aui-header-burger]');

    if (window.innerWidth > 1250) {
        headerElement.classList.remove('aui-header--is-open');
        burgerButton.classList.remove('aui-header-bar__actions-burger--is-open');
        document.body.classList.remove('aui-lock-scroll');
    }
});

window.Nette = netteForms;
netteForms.initOnLoad();
