import naja from 'naja';
import Slider from '../classes/slider';

export default function initSlider(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-slider]')?.forEach((sliderElement) => {
    Slider.init(sliderElement);
  });
}

naja.addEventListener('init', () => {
  initSlider(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  initSlider(event.detail.snippet);
});