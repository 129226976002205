import naja from 'naja';
import Ajax from 'adaptive-ui/src/js/utils/ajax';
import Translator from 'adaptive-ui/src/js/utils/translator';

class PromoCode {

    constructor(promoCode) {
        this.promoCode = promoCode;
        this.promoCodeSubmit= this.promoCode.querySelector('[data-promo-code-submit]');
        this.promoCodeInput = this.promoCode.querySelector('[data-promo-code-input]');
        this.promoCodeRemove = this.promoCode.querySelector('[data-promo-code-remove]');

        if (this.promoCodeSubmit && this.promoCodeInput) {
            this.promoCodeSubmit.addEventListener('click', (event) => this.onSubmit(event));
            this.promoCodeInput.addEventListener('keydown', (event) => {
                if (event.key === 'Enter') {
                    this.onSubmit(event);
                }
            });
        }

        if (this.promoCodeRemove) {
            this.promoCodeRemove.addEventListener('click', (event) => this.onRemove(event));
        }
    }

    onSubmit(event) {
        event.preventDefault();

        const code = this.promoCodeInput.value;

        if (code !== '') {
            Ajax.post(this.promoCode.dataset.promoCodeApplyUrl, { promoCode: code }, { history: false })
                .then((response) => {
                    if (response.result === false) {
                        this.promoCodeInput.value = '';

                        new window.aui.modal({
                            type: 'sm',
                            title: Translator.translate('Chyba'),
                            body: Translator.translate('Zadaný kód není správný.'),
                            buttons: {
                                cancel: {
                                    text: Translator.translate('Zavřít'),
                                    isVisible: true,
                                }
                            }
                        });
                    }
                });
        } else {
            new window.aui.modal({
                type: 'sm',
                title: Translator.translate('Chyba'),
                body: Translator.translate('Vyplňte prosím slevový kód.'),
                buttons: {
                    cancel: {
                        text: Translator.translate('Zavřít'),
                        isVisible: true,
                    }
                }
            });
        }
    }

    onRemove(event) {
        event.preventDefault();

        Ajax.post(event.target.getAttribute('href'), {}, { history: false });
    }

    static init(container) {
        container.querySelectorAll('[data-promo-code]').forEach((promoCode) => {
            new PromoCode(promoCode);
        });
    }

}

naja.addEventListener('init', () => {
    PromoCode.init(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    PromoCode.init(event.detail.snippet);
});
