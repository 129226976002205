import naja from 'naja';
import LoaderOverlay from '../utils/loader-overlay';

naja.addEventListener('init', () => {
  window.aui = {
    ...window.aui,
    loaderOverlayCount: {},
    loaderOverlayQueue: {},
  }
});

naja.addEventListener('start', (event) => {
  const loaderOverlayElement = LoaderOverlay.getElement(event.detail);

  // no loader element
  if (loaderOverlayElement === null) {
    return;
  }

  const loaderOverlayMessage = LoaderOverlay.getMessage(event.detail);

  if (!window.aui.loaderOverlayCount[loaderOverlayElement]) {
    window.aui.loaderOverlayCount[loaderOverlayElement] = 0;
  }

  window.aui.loaderOverlayCount[loaderOverlayElement]++;

  if (window.aui.loaderOverlayCount[loaderOverlayElement] === 1) {
    window.aui.loaderOverlayQueue[loaderOverlayElement] = LoaderOverlay.show(loaderOverlayElement, loaderOverlayMessage);
  }
});

const handleRequestEnd = (event) => {
  const loaderOverlayElement = LoaderOverlay.getElement(event.detail);

  // no loader element
  if (loaderOverlayElement === null) {
    return;
  }

  window.aui.loaderOverlayCount[loaderOverlayElement]--;

  if (window.aui.loaderOverlayCount[loaderOverlayElement] === 0) {
    LoaderOverlay.hide(window.aui.loaderOverlayQueue[loaderOverlayElement]);
  }
};

naja.addEventListener('complete', handleRequestEnd);
naja.addEventListener('error', handleRequestEnd);
naja.addEventListener('abort', handleRequestEnd);