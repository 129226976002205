import 'waypoints/lib/noframework.waypoints.js';

export default function animations() {
  // Waypoint animations
  let handleIsInviewClass = function (direction, el) {
    if (direction === 'up' && el.classList.contains('is-inview')) {
        el.classList.remove('is-inview');
    } else {
        el.classList.add('is-inview');
    }
  }

  const animationElements = document.querySelectorAll('[data-animation]');
  if (animationElements) {
    let defaultOffset = '60%';
    if(window.outerWidth < 800) {
        defaultOffset = '95%';
    }
    [].forEach.call(animationElements, function (el) {
        el.classList.add('is-enabled');
        const offset = el.getAttribute('offset') || defaultOffset;
        const waypointLetter = new Waypoint({
            element: el,
            handler: function (direction) {
                handleIsInviewClass(direction, this.element);
            },
            offset: offset,
        });
    });
    
    let resizeTimer;
    window.addEventListener('resize', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            // Run code here, resizing has "stopped"
            Waypoint.refreshAll();
        }, 250);
    });
  }
}

naja.addEventListener('init', () => {
  animations();
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  animations();
});