const initSlider = () => {
  const updateSliderInfo = (slider, auiSlider) => {
    const sliderInfo = slider.querySelector('[data-slider-info]');
    const sliderSlides = slider.querySelectorAll('.splide__slide');

    if (sliderInfo) {
      const currentSlide = sliderSlides[auiSlider.index];
      const currentSlideInfo = currentSlide.querySelector('.splide__slide-info');

      if (currentSlideInfo) {
        sliderInfo.innerHTML = currentSlideInfo.innerHTML;
      }
    }
  };

  const sliders = document.querySelectorAll('[data-slider]');

  const activeSlidersKeys = Object.keys(window.aui.activeSliders);

  activeSlidersKeys.forEach((activeSliderKey) => {
    const activeSlider = window.aui.activeSliders[activeSliderKey];

    activeSlider.root.style.visibility = 'hidden';

    setTimeout(() => {
      activeSlider.root.style.visibility = 'visible';
    }, 1000);
  });

  if (sliders?.length) {
    sliders.forEach((slider) => {
      const auiSlider = window.aui.activeSliders[slider.querySelector('[data-aui-slider]')?.getAttribute('data-aui-slider')];

      if (auiSlider) {
        updateSliderInfo(slider, auiSlider);
  
        auiSlider.on('move', () => updateSliderInfo(slider, auiSlider));

        const arrowLeft = slider.querySelector('[data-slider-arrow-left]');
        const arrowRight = slider.querySelector('[data-slider-arrow-right]');

        arrowLeft.onclick = () => {
          auiSlider.go(auiSlider.index - 1);
        };
        arrowRight.onclick = () => {
          auiSlider.go(auiSlider.index + 1)
        };  
      }
    });
  }
};

const initSliderCategory = () => {
  const sliderCategoryItems = document.querySelectorAll('[data-slider-category-items] button');
  const sliderCategory = window.aui.activeSliders.categorySlider;

  if (sliderCategoryItems?.length && sliderCategory) {
    sliderCategoryItems.forEach((item, index) => {
      item.addEventListener('click', () => {
        sliderCategory.go(index);
      });
    });

    sliderCategoryItems[0].classList.add('is-active');

    sliderCategory.on('move', (newIndex, prevIndex) => {
      sliderCategoryItems[prevIndex].classList.remove('is-active');
      sliderCategoryItems[newIndex].classList.add('is-active');
    });
  }
};


export default function slider() {
  initSlider();
  initSliderCategory();
}

naja.addEventListener('init', () => {
  slider();
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  slider();
});