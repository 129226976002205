function initNavigation(container) {
    const navigationDropdownItems = container.querySelectorAll(['.aui-header-bar .aui-navigation__dropdown', '.aui-header-bar .aui-navigation__dropdown-submenu']);

    if (navigationDropdownItems?.length) {
        navigationDropdownItems.forEach((navigationDropdownItem) => {
            navigationDropdownItem.addEventListener('mouseenter', () => {
                const submenuContainer = navigationDropdownItem.querySelector('.aui-navigation__item-submenu-container');

                const submenuContainerHeight = submenuContainer.offsetHeight;

                document.documentElement.style.setProperty('--header-padding-bottom-offset', `${submenuContainerHeight > 0 ? submenuContainerHeight : 0}px`);
            })

            navigationDropdownItem.addEventListener('mouseleave', () => {
                document.documentElement.style.setProperty('--header-padding-bottom-offset', '0px');
            })
        });
    }
}

naja.addEventListener('init', () => {
    initNavigation(document.body);
});

window.addEventListener('resize', () => {
    initNavigation(document.body);
});
