export default class Timeout {
  static setTimeoutAndClearPrevious (timeoutName, callback, timeoutMilliseconds) {
      Timeout.timeouts = Timeout.timeouts || {};

      if (typeof timeoutName !== 'string') {
          throw new Error('Missing timeout name.');
      }

      if (Timeout.timeouts.hasOwnProperty(timeoutName) === true) {
          clearTimeout(Timeout.timeouts[timeoutName]);
      }

      Timeout.timeouts[timeoutName] = setTimeout(callback, timeoutMilliseconds);
  }
}