export default class Video {
  static init(videoElement) {
    const type = videoElement.getAttribute('data-aui-video');
    const videoId = videoElement.getAttribute('data-aui-video-id');

    if (type && videoId) {
      switch (type) {
        case 'thumbnail-start':
          videoElement.addEventListener('click', () => {
            new aui.modal({
              type,
              title: 'Video',
              body: `
                <div class="aui-video">
                  <iframe
                    class="aui-video__video"
                    src="https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              `,
              buttons: {
                cancel: {
                  isVisible: false,
                },
                submit: {
                  isVisible: false,
                },
              },
            });
          });
  
          break;
      };
    }
  }
}