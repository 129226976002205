import naja from 'naja';
import Announcement from '../classes/announcement';

export default function initAnnouncement(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-announcement]')?.forEach((announcementElement) => {
    Announcement.init(announcementElement);
  });
}

naja.addEventListener('init', () => {
  initAnnouncement(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  initAnnouncement(event.detail.snippet);
});