import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

export default class Scroll {
  static initAutoScroll(scrollElement) {
    const axes = scrollElement.getAttribute('data-aui-auto-scroll')?.split(',');

    axes?.forEach((axis) => {
      switch (axis.toLowerCase()) {
        case 'x':
          scrollElement.scrollLeft = scrollElement.scrollWidth;
          break;
        case 'y':
          scrollElement.scrollTop = scrollElement.scrollHeight;
          break;
        default:
          break;
      }
    });
  }

  static initSmoothScroll(scrollElement) {
    scrollElement.addEventListener('click', (event) => {
      event.preventDefault();

      if (scrollElement.hasAttribute('data-aui-smooth-scroll-disabled')) {
        return;
      }

      const target = document.querySelector(scrollElement.getAttribute('data-aui-smooth-scroll') || scrollElement.getAttribute('href'));
      if (target) {
        const header = document.querySelector('header');
        let headerOffset = 0;

        if (header) {
          const headerStyles = getComputedStyle(header);

          if (headerStyles.position === 'fixed' || headerStyles.position === 'sticky') {
            headerOffset = header.getBoundingClientRect()?.height;
          }
        }

        const offset = scrollElement.getAttribute('data-aui-smooth-scroll-offset') || (headerOffset || 0);
        const behavior = scrollElement.getAttribute('data-aui-smooth-scroll-behavior') || 'smooth';

        window.scrollTo({
          behavior,
          top:
          target.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            offset,
        })
      }
    });
  }

  static initScrollToTop(scrollElement) {
    if (scrollElement.hasAttribute('fixed')) {
      window.addEventListener('scroll', () => {
        const scrollHeight = document?.body?.scrollTop || document?.documentElement?.scrollTop;
        if (scrollHeight) {
          const fixedShowOffsetPercent = Number(scrollElement.getAttribute('data-aui-go-to-top') || 0);
          const scrollHeightPercent = ((scrollHeight / (document?.body?.scrollHeight - window.innerHeight)) * 100);

          if (scrollHeightPercent > fixedShowOffsetPercent) {
            scrollElement.classList.add('aui-go-to-top--is-show');
          } else {
            scrollElement.classList.remove('aui-go-to-top--is-show');
          }
        }
      });
    }
  }
}