import Translator from '../utils/translator';

export default class LoaderOverlay {
  loaderOverlayQueue = [];

  static getElement(detail) {
    const { loaderElement, loaderMessage } = detail?.options;

    if (loaderElement === false || loaderElement === null || loaderElement === 'off') {
      return null;
    }

    let targetElement;

    if (loaderElement instanceof HTMLElement) {
      targetElement = loaderElement;
    } else if (typeof loaderElement === 'string') {
      targetElement = document.querySelector(loaderElement);
    } else {
      targetElement = document.body;
    }

    return targetElement;
  }

  static getMessage(detail) {
    const { loaderMessage } = detail?.options;

    return loaderMessage || Translator.translate('Načítám');
  }

  static show(loaderOverlayElement, loaderOverlayMessage) {
    if (loaderOverlayElement === null) {
      return;
    }

    // prepare mask
    const loaderOverlayMaskElement = document.createElement('div');
    loaderOverlayMaskElement.innerHTML = `
      <div class="aui-loader-overlay__mask" data-aui-loader-overlay-mask>
        <div class="aui-loader-overlay__mask-icon aui-icon">
          <svg class="circular" viewBox="0 0 50 50">
            <circle cx="25" cy="25" r="20" fill="none" stroke-dasharray="90,150" stroke-dashoffset="0" stroke-width="3" stroke="currentColor" stroke-linecap="round"></circle>
          </svg>
        </div>
        <div class="aui-loader-overlay__mask-content">
          <p>${loaderOverlayMessage}</p>
        </div>
      </div>
    `;

    loaderOverlayElement.insertAdjacentElement('beforeend', loaderOverlayMaskElement);
    loaderOverlayElement.classList.add('aui-loader-overlay');

    return loaderOverlayElement;
  }

  static hide(loaderOverlayElement) {
    if (loaderOverlayElement) {
      const loaderOverlayMaskElement = loaderOverlayElement.querySelector('[data-aui-loader-overlay-mask]');

      if (loaderOverlayMaskElement) loaderOverlayMaskElement.remove();
      loaderOverlayElement.classList.remove('aui-loader-overlay');
    }
  }
}