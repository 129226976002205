import Ajax from 'adaptive-ui/src/js/utils/ajax';
import Translator from 'adaptive-ui/src/js/utils/translator';

function initNewsletter(container) {
    const newsletterForms = container.querySelectorAll('[data-newsletter-form]');
    newsletterForms.forEach((form) => {
        form.addEventListener('submit', (event) => {
            event.preventDefault();
            event.stopImmediatePropagation();

            if (Nette.validateForm(event.target) === false) {
                return;
            }

            Ajax.submitForm(event.target)
                .then(() => {
                    new window.aui.modal({
                        body: Translator.translate('Byl(a) jste úspěšně přihlášena k odběru novinek.'),
                        type: 'sm',
                        title: Translator.translate('Úspěšně přihlášeno'),
                        buttons: {
                            cancel: {
                                text: Translator.translate('Zavřít'),
                                isVisible: true,
                            }
                        }
                    });
                });
        });
    });
}

// Inicializace při načtení stránky
naja.addEventListener('init', () => {
    initNewsletter(document.body);
});

// Inicializace po aktualizaci snippetů
naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    initNewsletter(event.detail.snippet);
});
