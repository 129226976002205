import Timeout from "adaptive-ui/src/js/utils/timeout";
import CartPreview from "./CartPreview";
import Ajax from 'adaptive-ui/src/js/utils/ajax';

export default class CartRecalculator {

    constructor(container) {
        const _this = this;

        // Reload content after delay when clicking +/- buttons
        container.querySelectorAll('[data-cart-item] [data-aui-spinbox-plus], [data-cart-item] [data-aui-spinbox-minus]')
            .forEach(button => {
                button.addEventListener('click', (event) => {
                    _this.reloadWithDelay(1000, _this);
                    event.preventDefault();
                });
            });

        // Reload content after delay when leaving input or pressing ENTER
        // But only if the value was changed
        container.querySelectorAll('[data-cart-item] [data-aui-spinbox-input]')
            .forEach(input => {
                input.addEventListener('change', () => {
                    input.addEventListener('blur', () => {
                        _this.reloadWithDelay(1000, _this);
                    });
                });

                input.addEventListener('keydown', (event) => {
                    if (event.key === "Enter") {
                        _this.reloadWithDelay(0, _this);
                        event.preventDefault();
                    }
                });
            });
    }

    reload() {
        const cartForm = document.querySelector('[data-cart-form]');
        if (cartForm) {
            Ajax.submitForm(cartForm, { loaderElement: cartForm }).then(() => CartPreview.triggerReload());
        }
    }

    reloadWithDelay(delay, cartRecalculator) {
        Timeout.setTimeoutAndClearPrevious(
            'cartRecalculator',
            function () {
                cartRecalculator.reload();
            },
            delay
        );
    }

    static init(container) {
        new CartRecalculator(container);
    }
}

naja.addEventListener('init', () => {
    CartRecalculator.init(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    CartRecalculator.init(event.detail.snippet);
});
