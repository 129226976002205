import Ajax from 'adaptive-ui/src/js/utils/ajax';

export default class OrderSummaryBox {

    static loadOrderSummaryBox() {
        const orderSummaryBox = document.querySelector('[data-order-summary-box]');
        const url = orderSummaryBox?.dataset.url;

        const checkedTransport = document.querySelector('[data-order-transport-input]:checked');
        const checkedPayment = document.querySelector('[data-order-payment-input]:checked');
        const selectedCountry = document.querySelector('.js-order-country-input');
        const companyTaxNumber = document.querySelector('.js-order-company-tax-number-input');

        const checkedTransportId = checkedTransport?.dataset.id;
        const checkedPaymentId = checkedPayment?.dataset.id;
        const selectedCountryId = selectedCountry?.value;
        const companyTaxNumberValue = companyTaxNumber?.value;

        const data = {};

        if (checkedTransportId !== undefined) {
            data['orderSummaryBox-transportId'] = checkedTransportId;
        }

        if (checkedPaymentId !== undefined) {
            data['orderSummaryBox-paymentId'] = checkedPaymentId;
        }

        if (selectedCountryId !== undefined) {
            data['orderSummaryBox-countryId'] = selectedCountryId;
        }

        if (companyTaxNumberValue !== undefined) {
            data['orderSummaryBox-companyTaxNumber'] = companyTaxNumberValue;
        }

        Ajax.get(url, data, { history: false, loaderElement: orderSummaryBox });
    }

    static init(container) {
        const elements = container.querySelectorAll(
            '[data-order-transport-input], [data-order-payment-input], .js-order-country-input, .js-order-company-tax-number-input, .js-ekokom-input'
        );
        elements.forEach(element => {
            element.addEventListener('change', OrderSummaryBox.loadOrderSummaryBox);
        });
    }
}

naja.addEventListener('init', (event) => {
    OrderSummaryBox.init(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    OrderSummaryBox.init(event.detail.snippet);
});
