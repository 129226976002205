import naja from 'naja';
import Tabs from '../classes/tabs';

export function initTabs(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-tabs]').forEach((tabs) => {
    const element = new Tabs(tabs);
    element.init();
  });
}

naja.addEventListener('init', () => {
  initTabs();
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
  initTabs(event.detail.snippet);
});
