
import SimpleLightbox from 'simplelightbox';
import GLightbox from 'glightbox'; // https://github.com/biati-digital/glightbox
import Helpers from '../helpers';

export default class Gallery {
  static init(galleryElement) {
    const randomIdentifierAttr = 'data-aui-gallery-id';
    const randomIdentifier = Helpers.generateRandomId();

    galleryElement.setAttribute(randomIdentifierAttr, randomIdentifier);

    const defaultOptions = {
      animationSlide: false,
      animationSpeed: 100,
      history: false,
      uniqueImages: false,
      uniqueItems: false,
      fileExt: false,
      overlayOpacity: Number(galleryElement.getAttribute('data-aui-gallery-overlay-opacity')) || 0.7,
    };

    new window.SimpleLightbox(`[${randomIdentifierAttr}="${randomIdentifier}"] a`, defaultOptions);
  }

  static async initLookbook(lookbookElement) { // TODO: Posjednocení gallery knihovny, tak nechat jen init() a inicialozovat přes data-aui-gallery místo data-aui-lookbook
    const lookbookItems = lookbookElement.querySelectorAll('[data-aui-lookbook-item]');

    if (lookbookItems?.length) {
      const randomIdentifier = Helpers.generateRandomId();

      lookbookItems.forEach((item) => {
        item.setAttribute('data-aui-lookbook-item', randomIdentifier);
      });

      GLightbox({
        selector: `[data-aui-lookbook-item="${randomIdentifier}"]`,
        touchNavigation: true,
      });
    }
  }
}