import Helpers from '../helpers';

import { CountUp } from 'countup.js';

export default class Counter {
  static init(counterElement) {
    const scrollListener = () => {
      if (Helpers.isElementInViewport(counterElement)) {
        new CountUp(counterElement, counterValue, options).start();

        window.removeEventListener('scroll', scrollListener);
      }
    };

    const counterValue = Number(counterElement.getAttribute('data-aui-counter'));
    const initMode = counterElement.getAttribute('data-aui-counter-init');
    const options = {
      separator: ' ',
    };

    if (isNaN(counterValue)) {
      return;
    }

    if (initMode === 'view') {
      window.addEventListener('scroll', scrollListener);
      return;
    }

    new CountUp(counterElement, counterValue, options).start();
  }
}