
export default class ShowSlide {
  static init(showSlideElement) {
    showSlideElement.addEventListener('click', () => {
      this.toggle(showSlideElement);
    });

    const elementType = showSlideElement.type;

    if ((elementType === 'checkbox' || elementType === 'radio') && showSlideElement.checked && !showSlideElement.getAttribute('data-aui-show-slide-inverted')) {
      this.toggle(showSlideElement);
    }
  }

  static toggle(showSlideElement) {
    const showSlideBodyElement = document.querySelector(showSlideElement.getAttribute('data-aui-show-slide'));

    if (showSlideBodyElement) {
      const isOpen = showSlideBodyElement.classList.contains('aui-show-slide--is-open');

      if (showSlideBodyElement ) {
        if (!isOpen) {
          const height = showSlideBodyElement.scrollHeight;
          showSlideBodyElement.style.height = height + 'px';
        } else {
          showSlideBodyElement.style.height = '0px';
        }

        showSlideBodyElement.classList.toggle('aui-show-slide--is-open');
        showSlideElement.classList.toggle('aui-show-slide--is-open');
      }
    }
  }
}