// Announcements
const announcementObserver = new MutationObserver((mutationsList, observer) => {
  for (const mutation of mutationsList){
    checkAnnouncementHeight();
  }
});

const updateHeaderHeight = (headerElement) => {
  document.documentElement.style.setProperty('--aui-header-height', `${headerElement.offsetHeight}px`);
};

const checkAnnouncementHeight = () => {
  const announcements = document.querySelector('.aui-announcements');

  if (announcements) {
    document.documentElement.style.setProperty('--aui-page-offset-top', `${announcements.offsetHeight ? announcements.offsetHeight - 1 : 0}px`);
  }
};

export default class Header {
  static async init(headerElement) {
    const burgerButton = headerElement.querySelector('[data-aui-header-burger]');

      const resizeObserver = new ResizeObserver(() => updateHeaderHeight(headerElement));
      resizeObserver.observe(headerElement);

    if (burgerButton) {
      burgerButton.addEventListener('click', () => {
        headerElement.classList.toggle('aui-header--is-open');
        burgerButton.classList.toggle('aui-header-bar__actions-burger--is-open');
        document.body.classList.toggle('aui-lock-scroll');
      });
    }

    if (headerElement.hasAttribute('data-aui-header-sticky')) {
      const announcements = document.querySelector('.aui-announcements');

      if (announcements) {  
          checkAnnouncementHeight();
          announcementObserver.observe(announcements, { childList: true, subtree: true });
          window.addEventListener('resize', () => checkAnnouncementHeight());
      }

      const { offsetTop } = headerElement;
        const process = () => {
            if (window.scrollY > offsetTop) {
              headerElement.classList.add('aui-header--pinned');
            } else {
              headerElement.classList.remove('aui-header--pinned');
            }
        }

        window.onscroll = process;

        process();
    }
  };
}